<template>
    <div>
        <v-card>
            <s-toolbar label="Seleccionar Pallet a distribuir"
                dark 
                close 
                @close="closeModal()"
                color="primary"
                save
                @save="saveDistribution()"
            >
            </s-toolbar>
            <v-card dense>
                
                <v-card class="pa-md-6 mx-lg-auto">
                    <v-row style="margin: auto;">		
                        <v-col
                            cols="6"
                            lg="3"
                            md="4"
                            v-for="pallet in itemPallet"
                            v-if="pallet.DbpID != DbpID"
                        >
                        
                            <v-badge color="white">
                                <template v-slot:badge>							 
                                    
                                    <v-checkbox
                                        style="margin-left: -8px;margin-top: 2px;"
                                        v-model="itemCheck"
                                        :value="pallet"
                                        @click="clickCheckBox(pallet)"
                                    >
                                    <!-- :value="pallet.DbpID" -->
                                    </v-checkbox>
                                                                
                                </template>
                                <v-card>								 
                                    <v-card-text>
                                        <b>ID Pallet : </b><v-chip>{{pallet.PalletGroupID}}</v-chip>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="space-around" no-gutters>
                                            <v-icon class="mdi-96px" >fa-pallet</v-icon>
                                        </v-row>
                                    </v-card-actions>
                                    <v-card-text class="text-center">
                                        <b>{{ 'N° Cajas : '+ pallet.NumberBoxes }}</b>
                                        <h4>{{ ' Peso (Kg) : '+ pallet.WeightNetBoxes }}</h4>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="space-around" no-gutters>
                                            <v-chip color="warning" >{{pallet.TraceabilityID}}</v-chip>
                                        </v-row>
                                    </v-card-actions>																
                                </v-card>	
                            </v-badge>
                        </v-col>
                    </v-row>
                </v-card>
                    
            </v-card>
        </v-card>
       
    </div>
</template>
<script>

    import _sFrzDistributionBoxesPalletsService from "@/services/FrozenProduction/FrzDistributionBoxesPallets.js";


    export default{
        components: {},
        props: {
			DbpID: {
                type : Number,
                required : true
            },
			PalletSelected: {
                type : Object,
                required : false,
            },
		},
        data:() =>({
            
           
            itemPallet: [],
            PalletChecked:[],
            itemCheck:[],
           
            // itemCheck:false,
            
            
        }),
        methods:{
            
            list(){
                _sFrzDistributionBoxesPalletsService.list({}, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                           this.itemPallet = r.data;
                           console.log('data list pallet',this.itemPallet);
                        }
                    });
            },
            closeModal(){
                this.$emit("closeModal")
            },
            clickCheckBox(item){
                console.log('checked',item);
                this.PalletChecked = item
            },
            saveDistribution(){
                let obj = {
                    pallet: this.itemCheck,
                }
                console.log('obj',obj.pallet);
                let list = [];
                obj.pallet.forEach(element => {
                    list.push(element.CipID);
                });

                let items = {};

                items.List = list;   
                items.CipIDOrigin = this.PalletSelected.CipID
                items.CountOfDivide = list.length;
                items.NumberBoxes = this.PalletSelected.NumberBoxes;
                items.WeightNetBoxes = this.PalletSelected.WeightNetBoxes;
                items.UsrUpdateID = this.$fun.getUserID();    
                items.UsrCreateID = this.$fun.getUserID();    
                console.log('lis check',items);
                let ResultOpr = 0
                ResultOpr = items.NumberBoxes / items.CountOfDivide 
                if(ResultOpr%1 == 0){

                }else{
                    this.$fun.alert("No se Puede distribuir "+items.NumberBoxes+" cajas entre "+items.CountOfDivide +" Pallets", "warning");
                    return;
                }
                // return
                _sFrzDistributionBoxesPalletsService.addRedistribuirBoxes(items, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {
                        this.$fun.alert("cajas distribuidas correctamente", "success");
                        this.$emit("closeModal")
                        this.$emit("RefreshList")
                    }
                });



            },

            // clickCheckBox(valueNew, valueold)
            // {
                
            //     if(this.itemCheck.length > 0)
            //     {
            //         if(valueold.length > 0)
            //         {
            //             console.log('true',)
                        
                        
            //         }
            //         if(valueNew.length > 0)
            //         {   
                       
            //            console.log('false',)

            //         }            
            //     }

            // },
            

        },
        created(){
           this.list();
        },
        watch:{
            // itemCheck(valueNew, valueold){               
            //     this.clickCheckBox(valueNew, valueold);
            // },
        }
    }
</script>