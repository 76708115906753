<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="8"
                    lg="11"
                    md="10"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                    >
                    </s-scanner-qr>
                </v-col>
            </v-row>
        </v-card>
        <br>
        <v-row>
            <v-col sm="12" md="12" >
                <v-card class="pa-md-6 mx-lg-auto">
                    <v-row style="margin: auto;">
                        <v-col v-if="btnEndProcess">
                            <v-btn  
                                color="info"
                                @click="ClickEndProcess()">
                                Terminar Proceso
                            </v-btn> 
                        </v-col>
                    </v-row>
                    <v-row style="margin: auto;"></v-row>
                    <v-row style="margin: auto;">	
                        <v-col
                            cols="6"
                            lg="3"
                            md="4"
                            v-for="pallet in itemPallet"
                            
                        >
                            <v-btn
                                x-small
                                fab
                                dark
                                :color="'success'"
                                style="margin-left: 0px; margin-top: -20px;position: absolute; z-index: 2;"
                                @click="distributionBoxesPallet(pallet)"
                            >
                                <v-icon style="font-size: 16px !important; ">fa-boxes</v-icon>
                            </v-btn>
                            <v-badge color="white">
                                <template v-slot:badge>							 
                                    <v-btn	
                                        style="margin-left: -29px;margin-top: 2px;"
                                        fab
                                        small
                                        color="white"
                                        @click="removePallet(pallet)"
                                    >
                                        <v-icon 
                                            color="error" 
                                            class="fas fa-trash"
                                            small>
                                        </v-icon>
                                    </v-btn>								 
                                </template>
                                <v-card>								 
                                    <v-card-text>
                                        <b>ID Pallet : </b><v-chip>{{pallet.PalletGroupID}}</v-chip>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="space-around" no-gutters>
                                            <v-icon class="mdi-96px" >fa-pallet</v-icon>
                                        </v-row>
                                    </v-card-actions>
                                    <v-card-text class="text-center">
										<b>{{ 'N° Cajas : '+ pallet.NumberBoxes }}</b>
										<h4>{{ ' Peso (Kg) : '+ pallet.WeightNetBoxes }}</h4>
									</v-card-text>
                                    <v-card-actions>
                                        <v-row justify="space-around" no-gutters>
                                            <v-chip color="warning" >{{pallet.TraceabilityID}}</v-chip>
                                        </v-row>
                                    </v-card-actions>																
                                </v-card>	
                            </v-badge>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialogDistributionBoxesPallet"
            v-if="dialogDistributionBoxesPallet"
            
            width="900"
        >
        <!-- persistent -->
            <distribution-boxes :DbpID = "DbpID" :PalletSelected="PalletSelected" @closeModal="closeModal()" @RefreshList="RefreshList()"></distribution-boxes>
        </v-dialog>
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import _sFrzDistributionBoxesPalletsService from "@/services/FrozenProduction/FrzDistributionBoxesPallets.js";
    import DistributionBoxes from './FrzDistributionsBoxes.vue'

    export default{
        components: { sScannerQr, DistributionBoxes},
        data:() =>({
            
            clearInput:false,
            configScann: {}, 
            dialogDistributionBoxesPallet:false,
            itemPallet: [],
            DbpID : 0,
            PalletSelected : {},
            btnEndProcess : false,

            
            
        }),
        methods:{
            onValue(val)
            {
                
                if (val == "Sin datos" || isNaN(val) || val == 0) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
                    let item = {};
                    item.PalletGroupID = val;
                    item.UsrCreateID = this.$fun.getUserID();

                    _sFrzDistributionBoxesPalletsService.save(item, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.$fun.alert("Pallet ingresado correctamente", "success");
                            this.list();
                        }
                    });
                }
            },
            list(){
                _sFrzDistributionBoxesPalletsService.list({}, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                           this.itemPallet = r.data;
                           console.log('data list pallet',this.itemPallet);

                            if ( this.itemPallet.length == 0 ) {
                                this.btnEndProcess = false;
                            }else
                            if ( this.itemPallet.length > 0 ) {
                                this.btnEndProcess = true;
                            }
                        }
                    });
            },
            RefreshList(){
                this.list();
            },
            removePallet(item){
                console.log('delete',item);
                let val = {
                    DbpID : item.DbpID,
                    UsrUpdateID : this.$fun.getUserID(),
                }
                this.$fun.alert("Desea eliminar Pallet", "question").then(r => {
                    if(r.value)
                    {
                        _sFrzDistributionBoxesPalletsService.DeletePallet(val, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Se elimino correctamente", "success");
                                this.list();
                            }
                        });
                    }
                })
            },
            distributionBoxesPallet(item){
                console.log('item',item)
                this.dialogDistributionBoxesPallet = true;
                this.DbpID = item.DbpID
                this.PalletSelected = item
            },
            closeModal(){
                this.dialogDistributionBoxesPallet = false;
            },  
            ClickEndProcess(){
                let list = [];
                this.itemPallet.forEach(element => {
                    list.push(element.DbpID);
                });
                let items = {};
                items.ListEndProcess = list;   
                items.UsrUpdateID    = this.$fun.getUserID();
                console.log('lis check',items);
                this.$fun.alert("Desea terminar proceso", "question").then(r => {
                    if(r.value)
                    {
                        _sFrzDistributionBoxesPalletsService.EndProcess(items, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Proceso terminado correctamente", "success");
                                this.list();
                            }
                        });
                    }
                })

            },
            

        },
        created(){
           this.list();
        }
    }
</script>