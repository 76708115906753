import Service from "../Service";

const resource = "FrzDistributionBoxesPallets/";

export default {
	save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },
    DeletePallet(obj, requestID) {
        return Service.post(resource + "DeletePallet", obj, {
            params: { requestID: requestID },
        });
    },
    addRedistribuirBoxes(obj, requestID) {
        return Service.post(resource + "addRedistribuirBoxes", obj, {
            params: { requestID: requestID },
        });
    },
    EndProcess(obj, requestID) {
        return Service.post(resource + "EndProcess", obj, {
            params: { requestID: requestID },
        });
    },    

};